import { combineReducers } from "redux";

import adminReducer from "./admin/reducer";

import spinnerReducer from "./spinner/reducer";


import { coinSellerReducer } from "./coinSeller/reducer";

export default combineReducers({
  admin: adminReducer,
 
  spinner: spinnerReducer,
  
  coinSeller: coinSellerReducer,

});
