import React, { useEffect } from "react";

// js
import "../assets/js/main.min.js";

// router
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

// css
import "../assets/css/main.min.css";
import "../assets/css/custom.css";

// components
import Navbar from "../component/navbar/Navbar";
import Topnav from "../component/navbar/Topnav";

import Dashboard from "./Dashboard";

import Profile from "./Profile";
import CoinSeller from "./CoinSeller";
import CoinSellerHistory from "../component/table/CoinSellerHistory";
import CoinAssignToUser from "../component/table/CoinAssignToUser.js";
import SuperSellerHistory from "../component/table/SuperSellerHistory.js";

const Admin = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname === "/admin" ||
      location.pathname === "/admin/dashboard"
    ) {
      navigate("/admin/dashboard");
    }
  }, [location.pathname, navigate]);

  return (
    <>
      <div className="page-container">
        <Navbar />
        <div className="page-content">
          <Topnav />
          <div className="main-wrapper">
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/adminProfile" element={<Profile />} />
              <Route path="/coinSeller" element={<CoinSeller />} />
              <Route path="/coinAssignToUser" element={<CoinAssignToUser />} />
              <Route path="/supersellerhistory" element={<SuperSellerHistory />} />

              <Route path="/coinSeller/history" element={<CoinSellerHistory />} />
              <Route path="*" element={<Dashboard />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
