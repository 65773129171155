import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import ReactSelect from "react-select";
import { getCoinSellerUniqueId } from "../../store/coinSeller/action";
import { useDispatch, useSelector } from 'react-redux';
// import { OPEN_ADMIN_DIALOG, CLOSE_ADMIN_DIALOG } from "../store/admin/types";
import { assignCoinToUser } from '../../store/admin/action';
import { Toast } from '../../util/Toast';


const CoinAssignToUser = () => {
    const { coinSellerId } = useSelector((state) => state.coinSeller);
    const { adminData, dialog: open } = useSelector((state) => state.admin);

    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [coinAssignToUser, setCoinAssignToUser] = useState("");
    const [data, setData] = useState();
    const [uniqueId, setUniqueId] = useState("");
    const [errors, setError] = useState({
        uniqueId: "",
        coinAssignToUser: "",
    });



    useEffect(() => {
        dispatch(getCoinSellerUniqueId(search));
    }, [dispatch, search]);


    useEffect(() => {
        setData(coinSellerId);
    }, [coinSellerId]);

    // const closePopup = () => {
    //     dispatch({ type: CLOSE_ADMIN_DIALOG });
    // };

    const handleSubmit = async () => {
        if (!uniqueId || !coinAssignToUser || coinAssignToUser < 0) {
            const error = {};
            if (!uniqueId) error.uniqueId = "UniqueId Is Required !";
            if (!coinAssignToUser) error.coinAssignToUser = "Coin Is Required !";
            if (coinAssignToUser < 0) error.coin = "Enter Correct Coin !";
            return setError({ ...error });
        } else {
            const data = {
                uniqueId: uniqueId,
                coin: parseInt(coinAssignToUser),
            };
          const res = await dispatch(assignCoinToUser(data));

          if(res){
              setUniqueId("");
              setCoinAssignToUser("");
              Toast("success", res.message);
          }
           
            // dispatch({ type: CLOSE_ADMIN_DIALOG });
        }
    };
    return (
        <div>

            <DialogTitle id="responsive-dialog-title" >
                <div className="d-flex justify-content-between align-items-center">
                    <h3 className="mb-3" style={{ color: "#e4eeff" }}>
                        Coin Assign To User
                    </h3>
                </div>
            </DialogTitle>

            <DialogContent>
                <div className="pt-1 px-1 pb-3 ">
                    <div className="d-flex flex-column mx-auto card p-4" style={{ maxWidth: "600px" }}>
                        <h3 className="mb-3" style={{ color: "#e4eeff" , textAlign: "center" }}> Wallet Balance : {adminData?.coin}</h3>

                        <form >
                            <div>
                                {/* <div className="form-group">
                                    <label className="mb-2 text-gray">Unique Id of User</label>
                                    <ReactSelect
                                        value={data?.find((option) => option?.uniqueId === uniqueId)}
                                        options={data}
                                        getOptionLabel={(option) => option?.uniqueId}
                                        formatOptionLabel={(option) => (
                                            <div className="country-option">
                                                <img
                                                    src={option?.image}
                                                    style={{
                                                        height: "30px",
                                                        width: "30px",
                                                        borderRadius: "50%",
                                                    }}
                                                    alt="country-image"
                                                />
                                                <span className="ms-3">{option?.uniqueId}</span>
                                            </div>
                                        )}
                                        onChange={(selectedOption) => {
                                            setUniqueId(selectedOption?.uniqueId);
                                            if (
                                                !selectedOption?.uniqueId ||
                                                selectedOption?.uniqueId === "uniqueId"
                                            ) {
                                                setError({
                                                    ...errors,
                                                    uniqueId: "Please select a uniqueId!",
                                                });
                                            } else {
                                                setError({
                                                    ...errors,
                                                    uniqueId: "",
                                                });
                                            }
                                        }}
                                        onInputChange={(inputValue) => {
                                            if (inputValue?.length > 12) {
                                                setSearch(inputValue);
                                            }
                                        }}
                                    />
                                    {errors.uniqueId && (
                                        <div className="ml-2 mt-1">
                                            <div className="pl-1 text__left">
                                                <span className="text-red">{errors.uniqueId}</span>
                                            </div>
                                        </div>
                                    )}
                                </div> */}

                                <div className="form-group">
                                    <label className="mb-2 mt-3 text-gray">UniqueId</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        required=""
                                        placeholder="UniqueId"
                                        value={uniqueId}
                                        onChange={(e) => {
                                            setUniqueId(e.target.value);
                                            if (!e.target.value) {
                                                return setError({
                                                    ...errors,
                                                    uniqueId: "Coin is Required!",
                                                });
                                            } else if (e.target.value < 0) {
                                                return setError({
                                                    ...errors,
                                                    uniqueId: "Enter Correct Coin !",
                                                });
                                            } else {
                                                return setError({
                                                    ...errors,
                                                    uniqueId: "",
                                                });
                                            }
                                        }}
                                    />
                                    {errors.uniqueId && (
                                        <div className="ml-2 mt-1">
                                            <div className="pl-1 text__left">
                                                <span className="text-red">{errors.uniqueId}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="form-group">
                                    <label className="mb-2 mt-3 text-gray">Coin</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        required=""
                                        placeholder="Coin"
                                        value={coinAssignToUser}
                                        onChange={(e) => {
                                            setCoinAssignToUser(e.target.value);
                                            if (!e.target.value) {
                                                return setError({
                                                    ...errors,
                                                    coinAssignToUser: "Coin is Required!",
                                                });
                                            } else if (e.target.value < 0) {
                                                return setError({
                                                    ...errors,
                                                    coinAssignToUser: "Enter Correct Coin !",
                                                });
                                            } else {
                                                return setError({
                                                    ...errors,
                                                    coinAssignToUser: "",
                                                });
                                            }
                                        }}
                                    />
                                    {errors.coinAssignToUser && (
                                        <div className="ml-2 mt-1">
                                            <div className="pl-1 text__left">
                                                <span className="text-red">{errors.coinAssignToUser}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="mt-5 text-end">
                                <button
                                    type="button"
                                    className="btn btn-round btn-danger"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </DialogContent>

        </div>
    )
}

export default CoinAssignToUser
