import axios from "axios";
import { Toast } from "../../util/Toast";

import { SET_ADMIN, SIGNUP_ADMIN, UNSET_ADMIN, UPDATE_PROFILE, GET_PROFILE, ASSIGN_COIN_TO_USER } from "./types";
import { apiInstanceFetch } from "../../util/api";
import { projectName } from "../../util/Config";

export const signupAdmin = (signup) => (dispatch) => {
  axios
    .post("/admin/signup", signup)
    .then((res) => {
      console.log(res);
      if (res.data.status) {
        dispatch({ type: SIGNUP_ADMIN });
        Toast("success", "Signup Successfully!");
        setTimeout(() => {
          window.location.href = "/login";
        }, 10);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      Toast("error", error);
      console.log(error);
    });
};

export const login = (data) => (dispatch) => {
  axios
    .post("superSeller/login", data)
    .then((res) => {
      if (res.data.status) {
        
        Toast("success", `You have successfully logged in ${projectName}`);
        setTimeout(() => {
          window.location.href = "/admin/dashboard";
        }, 10);
        dispatch({ type: SET_ADMIN, payload: res.data.token });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) =>{
      Toast("error", error.message);

    });
};


export const getProfile = () => (dispatch) => {
  apiInstanceFetch
    .get("superSeller/profile")
    .then((res) => {
      if (res.status) {
        dispatch({ type: GET_PROFILE, payload: res.data });
      } else {
        Toast("error", res.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};

export const changePassword = (data) => (dispatch) => {
  axios
    .put("superSeller/changePassword", data)
    .then((res) => {
      
      if (res.data.status) {
        Toast("success", "Password changed successfully.");
        setTimeout(() => {
          dispatch({ type: UNSET_ADMIN });
          window.location.href = "/";
        }, [3000]);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const updateNameEmail = (data) => (dispatch) => {
  axios
    .patch("superSeller/updateProfile", data)
    .then((res) => {
      
      if (res.data.status) {
        Toast("success", "Profile updated successfully.");
        dispatch({ type: UPDATE_PROFILE, payload: res.data.data });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const assignCoinToUser = (data) => (dispatch) => {
return  axios
    .patch(
      `superSeller/coinFromSuperSellerToUser`, data
    )
    .then((res) => {
      debugger

      if (res.data.status === true) {
        dispatch({
          type: ASSIGN_COIN_TO_USER,
          payload: res.data.data,
        });


        return res.data
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};


