import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { getCoinSellerHistory, getSuperSellerHistory } from "../../store/coinSeller/action";
//pagination
import { TablePagination } from "@mui/material";
import Pagination from "../../pages/Pagination";
import { useDispatch } from "react-redux";

const TablePaginationActions = React.lazy(() => import("./TablePagination"));

const SuperSellerHistory = (props) => {
    const location = useLocation();

    let history_ = location?.location?.state;
    const { superSellerHistory, totalCoin, totalSuperSellerHistory  } = useSelector(
        (state) => state.coinSeller
    );

    const dispatch = useDispatch()
    const [data, setData] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        dispatch(getSuperSellerHistory(activePage, rowsPerPage));
    }, [location, activePage, rowsPerPage]);

    useEffect(() => {
        setData(superSellerHistory);
    }, [superSellerHistory]);

    //   pagination

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const handleRowsPerPage = (value) => {
        setActivePage(1);
        setRowsPerPage(value);
    };
    return (
        <>
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                    </div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav
                            aria-label="breadcrumb"
                            className="breadcrumb-header float-start float-lg-end"
                        >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard" className="text-danger">
                                        Dashboard
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active " aria-current="page">
                                    CoinSeller History
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="card" id="card">
                        <div class="card-body card-overflow pt-0">
                            <div className="d-flex justify-content-between mt-3">
                                <h4 className="text-white">Coin History</h4>

                                <span className="text-danger ">
                                    Total Coin :
                                    <span className="text-info">&nbsp;&nbsp;{totalCoin}</span>
                                </span>
                            </div>
                            <table class="table table-striped mt-5">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th>CoinSeller Id</th>
                                        <th>Mobile Number</th>
                                        <th>Coin</th>
                                        <th>Purchase Date </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.length > 0 ? (
                                        data.map((data, index) => {
                                            var date = data?.date ? data?.date?.split(",") : [];
                                            return (
                                                <tr key={index}>
                                                    <td>{(activePage - 1) * rowsPerPage + index + 1}</td>
                                                    <td className="text-capitalize">{data?.message ? data?.message : "-"}</td>
                                                    <td>{data?.coinSeller?.uniqueId ? data?.coinSeller?.uniqueId : "-"}</td>
                                                    <td>{data?.coinSeller?.mobileNumber ? data?.coinSeller?.mobileNumber : "-"}</td>

                                                    <td
                                                        className={`${(data?.type === 1 && data?.coin > 0) || (data?.type === 2 && data?.coin > 0)
                                                            ? "text-success" : (data?.type === 2 && data?.coin < 0) || (data?.type === 2 && data?.coin < 0) ?
                                                                "text-warning" : "-"
                                                            } fw-bold`}
                                                    >
                                                        {data?.type === 0
                                                            ? "+" + " " + data?.coin : (data?.type === 2 || data?.type === 3) ?
                                                                "-" + " " + data?.coin : "-"}
                                                    </td>
                                                    <td>{data?.date ? data?.date : ""}</td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="20" align="center">
                                                Nothing to show!!
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            <Pagination
                                activePage={activePage}
                                rowsPerPage={rowsPerPage}
                                userTotal={totalSuperSellerHistory}
                                handleRowsPerPage={handleRowsPerPage}
                                handlePageChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(null, { getCoinSellerHistory })(SuperSellerHistory);
