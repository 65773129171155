import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Chart as ChartJS } from "chart.js/auto";
import { Line, Doughnut, Chart } from "react-chartjs-2";

//dayjs
import dayjs from "dayjs";

//datepicker
import "bootstrap-daterangepicker/daterangepicker.css";


import { connect, useDispatch, useSelector } from "react-redux";

// Register the date adapter

const Dashboard = (props) => {


  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Dashboard</h3>
          </div>

        </div>
      </div>
    </>
  );
};

export default  Dashboard
